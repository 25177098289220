.WrapperReservation {
    display: flex;
    flex-direction: column;
    height: flex;
    background-color: white;
    margin-top: 56px;
    margin-bottom: 32px;
    border-radius: 20px;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 24px;
    padding-right: 24px;
}

.WrapperOuterReservation {
    opacity: 0; /* Initially hidden */
    transform: translateY(-50px); /* Start from slightly below */
    transition: opacity 1s ease, transform 1s ease; /* Smooth fade-in and slide-up transition */
}

.WrapperOuterReservation.is-visible {
    opacity: 1; /* Visible when in view */
    transform: translateY(0); /* Slide up to original position */
}

.Title {
    font-family: Mulish-Black;
    color: #C4AB90;
}

.JapanTitle {
    font-family: Mulish;
    color: #C4AB90;
    margin-bottom: 12px;
}

.TitleDescription {
    margin-top: 16px;
    margin-top: 24px;
    margin-bottom: 24px;
}

.TitleDescription > p {
    font-family: Mulish;
    color: #626061;
    text-align: center;
}

.HeaderBorder {
    width: 100%;
    height: 1px;
    background-color: #E3E3E3;
    margin: 0 auto;
    transform: rotate(180deg);
}

.ReservationBody {
    margin-top: 24px;
}

.textField {
    margin-top: 21px;
}

.ButtonArea {
    display: flex;
    margin-top: 21px;
    align-items: start;
}

.note {
    margin-top: 8px;
}

.note > p {
    text-align: start;
    font-family: Mulish-Italic;
    color: #626061;
}

/* Landscape styling */
@media only screen and (orientation: landscape) {
    .WrapperReservation {
        width: 70%;
        align-self: center;
    }

    .WrapperOuterReservation {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Title {
        font-size: 22px;
    }

    .JapanTitle {
        font-size: 14px;
    }

    .TitleDescription > p {
        font-size: 14px;
    }

    .note > p {
        font-size: 14px;
    }
}

@media only screen and (orientation: portrait) {
    .WrapperReservation {
        margin-left: 24px;
        margin-right: 24px;
    }

    .Title {
        font-size: 4.7vw; /* 20pt */
    }

    .JapanTitle {
        font-size: 3.5vw; /* 12pt */
    }

    .TitleDescription > p {
        font-size: 3.1vw; /* 12pt */
    }

    .note > p {
        font-size: 3.1vw; /* 12pt */
    }
}