.pinterest-grid {
    column-count: 2; /* Number of columns */
    column-gap: 12px; /* Gap between columns */
}

.pinterest-grid-item {
    break-inside: avoid; /* Prevent items from breaking inside columns */
    margin-bottom: 12px; /* Margin bottom for spacing */
}

.pinterest-grid-item img {
    width: 100%; /* Ensure images fill their containers */
    display: block; /* Remove any default image spacing */
    border-radius: 8px;
}