.WrapperGroomBride {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.GroomSection {
    margin-top: 56px;
}

.GroomImageSection, .BrideImageSection {
    display: inline-block;
    position: relative;
    opacity: 0;
    transition: opacity 1s ease;
}

.GroomImageSection.is-visible, .BrideImageSection.is-visible {
    opacity: 1; /* Visible when in view */
}

.groomNameJapan {
    display: block;
    position: absolute;
    width: 14%;
    top: 5px;
    right: -12px;
}

.groomFlowerOverlay {
    display: block;
    position: absolute;
    width: 14%;
    top: 75px;
    right: -12px;
}

.brideNameJapan {
    display: block;
    position: absolute;
    width: 14%;
    top: 5px;
    left: -14px;
}

.brideFlowerOverlay {
    display: block;
    position: absolute;
    width: 14%;
    top: 115px;
    left: -14px;
}

.groomImage, .brideImage {
    width: 192px;
    height: 260px;
    border-radius: 8px;
}

.NameDescription {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    opacity: 0; /* Initially hidden */
    transform: translateY(-50px); /* Start from slightly below */
    transition: opacity 1s ease, transform 1s ease; /* Smooth fade-in and slide-up transition */
}

.NameDescription.is-visible {
    opacity: 1; /* Visible when in view */
    transform: translateY(0); /* Slide up to original position */
}

.NameDescription > p.title {
    font-family: Mulish;
    color: #626061;
}

.NameDescription > p.name {
    margin-top: 2px;
    font-family: Mulish-Black;
    color: #C4AB90;
}

.NameDescription > p.parents > p {
    margin-top: 12px;
    font-family: Mulish;
    color: #626061;
    text-align: start;
}

.divider {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 40px;
    margin-bottom: 40px;
    opacity: 0; /* Initially hidden */
    transform: translateY(-50px); /* Start from slightly below */
    transition: opacity 1s ease, transform 1s ease; /* Smooth fade-in and slide-up transition */
}

.divider.is-visible {
    opacity: 1; /* Visible when in view */
    transform: translateY(0); /* Slide up to original position */
}

.divider > p {
    position: relative;
    font-family: Mulish-Italic;
    font-size: 3.7vw; /* 16pt */
    color: #C4AB90;
}

.borderLeft {
    content: ''; /* Required for pseudo-elements to appear */
    position: absolute; /* Position the pseudo-elements relative to the container */
    top: 50%; /* Align vertically centered relative to the container */
    height: 1px; /* Height of the border */
    background-color: #C4AB90; /* Color of the border */
    width: calc(35% - 24px); /* Adjust as needed */

    /* Adjust left and right positions to create the centered effect */
    left: 0;
    right: 0;

    /* Ensure the border doesn't overlap text */
    transform: translateY(-50%);
}

.borderRight {
    content: ''; /* Required for pseudo-elements to appear */
    position: absolute; /* Position the pseudo-elements relative to the container */
    top: 50%; /* Align vertically centered relative to the container */
    height: 1px; /* Height of the border */
    background-color: #C4AB90; /* Color of the border */
    width: calc(35% - 24px); /* Adjust as needed */

    /* Adjust left and right positions to create the centered effect */
    left: 50;
    right: 0;

    /* Ensure the border doesn't overlap text */
    transform: translateY(-50%);
}

.BrideGroomClosing {
    margin-top: 56px;
    margin-bottom: 72px;
    opacity: 0; /* Initially hidden */
    transition: opacity 1s ease, transform 1s ease; /* Smooth fade-in and slide-up transition */
}

.BrideGroomClosing.is-visible {
    opacity: 1; /* Visible when in view */
}

.BrideGroomClosing > p {
    font-family: Mulish-Italic;
    color: #626061;
}

/* Landscape styling */
@media only screen and (orientation: landscape) {
    .GroomSection {
        display: flex;
        flex-direction: row;
    }
    .BrideSection {
        display: flex;
        flex-direction: row;
    }
    .GroomImageSection {
        margin-right: 50px;
    }
    .BrideImageSection {
        margin-left: 50px;
    }
    .NameDescription > p.title {
        font-size: 16px;
        text-align: start;
    }
    .NameDescription > p.name {
        font-size: 32px;
        text-align: start;
    }
    .NameDescription > p.parents > p {
        font-size: 12px;
    }
    .BrideGroomClosing > p {
        font-size: 16px;
    }
}

@media only screen and (orientation: portrait) {
    .NameDescription > p.title {
        font-size: 3.7vw; /* 16pt */
    }
    .NameDescription > p.name {
        font-size: 7.5vw; /* 32pt */
    }
    .NameDescription > p.parents > p {
        font-size: 3.1vw; /* 12pt */
        text-align: center;
    }
    .BrideGroomClosing > p {
        font-size: 3.7vw; /* 16pt */
    }
}