.WrapperAddress {
    display: flex;
    width: 100%;
    height: flex;
    flex-direction: column;
}

.LocationDetails {
    opacity: 0; /* Initially hidden */
    transition: opacity 1s ease, transform 1s ease; /* Smooth fade-in and slide-up transition */
}

.LocationDetails.is-visible {
    opacity: 1; /* Visible when in view */
}

.Date {
    font-family: Mulish-Black;
    color: #C4AB90;
    margin-bottom: 32px;
}

.Date > p {
    font-family: Mulish-Black;
    color: #C4AB90;
}

.WeddingReception {    
    margin-top: 48px;
}

.HolyMatrimonyText, .WeddingReceptionText {
    font-family: Mulish;
    color: #626061;
    margin-bottom: 8px;
}

.HolyMatrimonyDateTime, .WeddingReceptionDateTime, .HotelName {
    font-family: Mulish-Black;
    color: #626061;
    margin-bottom: 8px;
}

.HolyMatrimonyDistrict, .WeddingReceptionDistrict {
    font-family: Mulish;
    color: #626061;
    margin-bottom: 16px;
}

.dressCode {
    margin-top: 56px;
    margin-bottom: 56px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: flex;
}

.dressCodeSuggestion {
    margin-bottom: 24px;
    opacity: 0; /* Initially hidden */
    transform: translateY(-50px); /* Start from slightly below */
    transition: opacity 1s ease, transform 1s ease; /* Smooth fade-in and slide-up transition */
}

.dressCodeSuggestion > p {
    font-family: Mulish-Italic;
    color: #626061;
}

.dressCodeSuggestion.is-visible {
    opacity: 1; /* Visible when in view */
    transform: translateY(0); /* Slide up to original position */
}

.colorPallete {
    display: flex;
    width: 100%;
    justify-content: center;
}

.cream {
    background-color: #FEEACA;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-right: 8px;
    opacity: 0; /* Initially hidden */
    transform: translateX(30px); /* Start from slightly below */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth fade-in and slide-up transition */
}

.cream.start-animate {
    opacity: 1;
    transform: translateX(0); /* Slide up to original position */
}

.lightBrown {
    background-color: #CEB69B;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-right: 8px;
    opacity: 0; /* Initially hidden */
    transform: translateX(30px); /* Start from slightly below */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth fade-in and slide-up transition */
}

.lightBrown.start-animate {
    opacity: 1;
    transform: translateX(0); /* Slide up to original position */
    transition-delay: 0.3s;
}

.gray {
    background-color: #626061;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-right: 8px;
    opacity: 0; /* Initially hidden */
    transform: translateX(30px); /* Start from slightly below */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth fade-in and slide-up transition */
}

.gray.start-animate {
    opacity: 1;
    transform: translateX(0); /* Slide up to original position */
    transition-delay: 0.6s;
}

.black {
    background-color: #231F20;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-right: 8px;
    opacity: 0; /* Initially hidden */
    transform: translateX(30px); /* Start from slightly below */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth fade-in and slide-up transition */
}

.black.start-animate {
    opacity: 1;
    transform: translateX(0); /* Slide up to original position */
    transition-delay: 0.9s;
}

.white {
    background-color: #FFFFFF;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    opacity: 0; /* Initially hidden */
    transform: translateX(30px); /* Start from slightly below */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth fade-in and slide-up transition */
}

.white.start-animate {
    opacity: 1;
    transform: translateX(0); /* Slide up to original position */
    transition-delay: 1.2s;
}

/* Landscape styling */
@media only screen and (orientation: landscape) {
    .Date {
        font-size: 22px;
    }
    .Date > p {
        font-size: 22px;
    }
    .HolyMatrimonyText, .WeddingReceptionText {
        font-size: 14px;
    }
    .HolyMatrimonyDateTime, .WeddingReceptionDateTime { 
        font-size: 18px;
    }
    .HolyMatrimonyDistrict, .WeddingReceptionDistrict {
        font-size: 12px;
    }
    .dressCodeSuggestion > p {
        font-size: 16px;
    }
}

@media only screen and (orientation: portrait) {
    .Date {
        font-size: 4.7vw; /* 20pt */
    }
    .Date > p {
        font-size: 4.7vw; /* 20pt */
    }
    .HolyMatrimonyText, .WeddingReceptionText {
        font-size: 3.5vw; /* 14pt */
    }
    .HolyMatrimonyDateTime, .WeddingReceptionDateTime {
        font-size: 4.1vw; /* 18pt */
    }
    .HolyMatrimonyDistrict, .WeddingReceptionDistrict {
        font-size: 3.1vw; /* 12pt */
    }
    .dressCodeSuggestion > p {
        font-size: 3.7vw; /* 16pt */
    }
}