.flowerDivider {
    width: 29px;
    height: 29px;
    margin-bottom: 32px;
}

.WrapperComments {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0; /* Initially hidden */
    transform: translateY(-50px); /* Start from slightly below */
    transition: opacity 1s ease, transform 1s ease; /* Smooth fade-in and slide-up transition */
}

.WrapperComments.is-visible {
    opacity: 1; /* Visible when in view */
    transform: translateY(0); /* Slide up to original position */
}

.EmptyWishesSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.EmptyWishesTextWrapper {
    width: 100%;
    height: auto;
}

.EmptyWishesText {
    font-family: Mulish-Italic;
    color: #626061;
    margin-bottom: 4px;
    width: 100%;
    height: 100%;
    margin-top: -100px;
}

.CommentBoard {
    width: 77.5%;
    display: flex;
    height: 560px;
    flex-direction: column;
    background-color: white;
    margin: 0 24px 56px 24px;
    padding: 24px 24px 0 24px;
    border-radius: 20px;
    overflow-y: auto;
}

.CommentBoardHeader {
    display: flex;
    width: 100%;
    height: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.CommentBoardTitle {
    font-family: Mulish-Black;
    color: #C4AB90;
}

.CommentBoardSubtitle {
    font-family: Mulish;
    color: #C4AB90;
    margin-bottom: 4px;
}

.CommentCard {
    display: flex;
    background-color: #F6F6F5;
    border-radius: 8px;
    flex-direction: column;
    padding: 12px 16px 12px 16px;
    margin-bottom: 8px;
}

.CommentName {
    font-family: Mulish-Black;
    color: #626061;
    margin-bottom: 4px;
    text-align: start;
}

.CommentText {
    font-family: Mulish;
    color: #626061;
    text-align: start;
}

/* Landscape styling */
@media only screen and (orientation: landscape) {
    .CommentBoard {
        width: 70%;
    }

    .CommentBoardTitle {
        font-size: 22px;
    }

    .CommentBoardSubtitle {
        font-size: 16px;
    }

    .CommentName {
        font-size: 14px;
    }

    .CommentText {
        font-size: 14px;
    }

    .EmptyWishesText {
        font-size: 1.5vw; /* 16pt */
    }
}

@media only screen and (orientation: portrait) {
    .CommentBoardTitle {
        font-size: 4.7vw; /* 20pt */
    }

    .CommentBoardSubtitle {
        font-size: 3.5vw; /* 14pt */
    }

    .CommentName {
        font-size: 3.1vw; /* 12pt */
    }

    .CommentText {
        font-size: 3.1vw; /* 12pt */
    }

    .EmptyWishesText {
        font-size: 4.1vw; /* 16pt */
    }
}