.WrapperGift {
    display: flex;
    background-color: #C4AB90;
    flex-direction: column;
    padding: 40px 24px 40px 24px;
    opacity: 0; /* Initially hidden */
    transform: translateY(50px); /* Start from slightly below */
    transition: opacity 1s ease, transform 1s ease; /* Smooth fade-in and slide-up transition */
}

.WrapperGift.is-visible {
    opacity: 1; /* Visible when in view */
    transform: translateY(0); /* Slide up to original position */
}

.GiftHeader {
    margin-bottom: 24px;
}

.GiftHeaderTitle {
    font-family: Mulish-Black;
    color: white;
}

.GiftHeaderSubtitle {
    font-family: Mulish;
    color: white;
}

.GiftDescription {
    margin-bottom: 24px;
}

.GiftDescription > p {
    font-family: Mulish;
    color: white;
    text-align: center;
}

.BankAccount {
    display: flex;
    justify-content: center;
    align-items: center;
}

.NumberBank {
    display: flex;
    justify-content: center;
}

.OneAccount {
    display: flex;
    flex-direction: column;
}

.NumberBank > p {
    font-family: Mulish-Black;
    color: white;
    margin-right: 4px;
    margin-bottom: 4px;
    text-align: start;
    width: inherit;
}

.OneAccount > p {
    font-family: Mulish;
    color: white;
    text-align: center;
}

.DividerAccount {
    width: 1px;
    height: 44px;
    background-color: white;
}

/* Landscape styling */
@media only screen and (orientation: landscape) {
    .GiftHeaderTitle {
        font-size: 22px
    }
    
    .GiftHeaderSubtitle {
        font-size: 16px;
    }

    .GiftDescription > p {
        font-size: 14px;
    }

    .NumberBank > p {
        font-size: 22px;
    }
    
    .OneAccount > p {
        font-size: 14px;
    }

    .DividerAccount {
        margin-left: 130px;
        margin-right: 130px;
    }
}

@media only screen and (orientation: portrait) {
    .GiftHeaderTitle {
        font-size: 4.7vw; /* 20pt */
    }
    
    .GiftHeaderSubtitle {
        font-size: 3.5vw; /* 14pt */
    }

    .GiftDescription > p {
        font-size: 3.1vw; /* 12pt */
    }

    .NumberBank > p {
        font-size: 4.7vw; /* 20pt */
    }
    
    .OneAccount > p {
        font-size: 3.1vw; /* 12pt */
    }

    .DividerAccount {
        margin: 0 auto;
        transform: rotate(360deg);
    }
}