.WrapperAddOnImage {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.topLandscape, .bottomLeft, .bottomRight { 
    border-radius: 8px;
}

/* Landscape styling */
@media only screen and (orientation: landscape) {
    .WrapperAddOnImage {
        width: 75%;
    }

    .topLandscape {
        width: 100%;
    }

    .BottomPart {
        margin-top: 16px;
        width: 100%;
        display: flex;
        align-items: start;
    }
    
    .bottomLeft {
        width: 39.2%;
        margin-right: 20px;
    }

    .bottomRight {
        width: 59%;
    }
}

@media only screen and (orientation: portrait) {
    .WrapperAddOnImage {
        width: 100%;
    }

    .topLandscape {
        width: 100%;
    }

    .BottomPart {
        margin-top: 8px;
        width: 100%;
        display: flex;
        align-items: start;
        height: auto;
    }

    .bottomLeft {
        width: 39.2%;
        margin-right: 8px;
    }

    .bottomRight {
        width: 59.1%;
    }
}