button {
    user-select: none;
    text-align: center;
    border: 1.5px solid #C4AB90;
    vertical-align: middle;
    white-space: nowrap;
    background-color: transparent;
    font-family: Mulish-Black;
    color: #C4AB90;
    border-radius: 24rem;
}

button:disabled {
    opacity: 50%;
}

@media only screen and (orientation: portrait) {
    button {
        font-size: 3vw;
    }
}

@media only screen and (orientation: landscape) {
    button {
        font-size: 12px;
    }
}