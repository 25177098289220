.WrapperIllustration {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: flex;
    align-items: center;
}

.firstDateTimeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: flex;
    opacity: 0; /* Initially hidden */
    transform: translateY(-50px); /* Start from slightly below */
    transition: opacity 1s ease, transform 1s ease; /* Smooth fade-in and slide-up transition */
}

.marriedDateTimeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: flex;
    opacity: 0; /* Initially hidden */
    transform: translateY(50px); /* Start from slightly below */
    transition: opacity 1s ease, transform 1s ease; /* Smooth fade-in and slide-up transition */
}

.firstDateTimeline.is-visible, .marriedDateTimeline.is-visible {
    opacity: 1; /* Visible when in view */
    transform: translateY(0); /* Slide up to original position */
}

.firstDateTimeline > p, .marriedDateTimeline > p {
    font-family: Mulish;
    color: #231F20;
}

.borderVertical {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 1px;
    height: 62px;
    background-color: #231F20;
    transform: rotate(180deg);
}

.photoIllustration {
    display: inline-block;
    position: relative;
}

.photostrip {
    opacity: 0; /* Initially hidden */
    transform: translateY(-50px); /* Start from slightly below */
    transition: opacity 1s ease, transform 1s ease; /* Smooth fade-in and slide-up transition */
}

.photostrip.is-visible {
    opacity: 1; /* Visible when in view */
    transform: translateY(0); /* Slide up to original position */
}

.photostripJapanText {
    display: block;
    position: absolute;
    width: 9%;
    right: -32px;
    bottom: 7px;
    opacity: 0; /* Initially hidden */
    transform: translateY(-50px); /* Start from slightly below */
    transition: opacity 1s ease, transform 1s ease; /* Smooth fade-in and slide-up transition */
}

.photostripJapanText.is-visible {
    opacity: 1; /* Visible when in view */
    transform: translateY(0); /* Slide up to original position */
}

.closingText {
    margin-top: 72px;
    margin-bottom: 40px;
    opacity: 0; /* Initially hidden */
    transform: translateY(-50px); /* Start from slightly below */
    transition: opacity 1s ease, transform 1s ease; /* Smooth fade-in and slide-up transition */
}

.closingText.is-visible {
    opacity: 1; /* Visible when in view */
    transform: translateY(0); /* Slide up to original position */
}

.closingText > p {
    font-family: Mulish-Italic;
    color: #626061;
}

/* Landscape styling */
@media only screen and (orientation: landscape) {
    .firstDateTimeline > p, .marriedDateTimeline > p {
        font-size: 22px;
    }
    .closingText > p {
        font-size: 16px;
    }
}

@media only screen and (orientation: portrait) {
    .firstDateTimeline > p, .marriedDateTimeline > p {
        font-size: 5.1vw; /* 22pt */
    }
    .closingText > p {
        font-size: 3.7vw; /* 16pt */
    }
}