.App {
  display: flex;
  flex-direction: column;
  text-align: center;
	margin: 0px;
	padding: 0px;
}

* {
  margin: 0;
  padding: 0;
}

.Groom {
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#opener {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-color: #F9F4E9;
}

#Wrapper.enabled {
  animation: flewOutOpener 1.5s;
}

.PhotoGrid {
  margin-top: 56px;
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  opacity: 0; /* Initially hidden */
  transform: translateY(-50px); /* Start from slightly below */
  transition: opacity 1s ease, transform 1s ease; /* Smooth fade-in and slide-up transition */
}

.PhotoGrid.is-visible {
  opacity: 1; /* Visible when in view */
  transform: translateY(0); /* Slide up to original position */
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.headerModal {
  font-family: Mulish-Black;
  color: #626061;
  margin-bottom: 4px;
}

.modalSubtitle {
  font-family: Mulish;
  color: #626061;
  margin-bottom: 16px;
}

.AudioPlayer {
  opacity: 0;
}

.AudioPlayer.is-visible {
  opacity: 1;
  transition: opacity 1s ease; /* Smooth fade-in and slide-up transition */
}

.YoutubeVideo {
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 8px;
}

.YoutubeVideo iframe {
  position: absolute;
}

.AddOnImage {
  width: 100vw;
  background-color: green;
}

@keyframes flewOutOpener {
  from {
    margin-top: 0px;
  }
  to {
    margin-top: var(--target-height);
  }
}

@font-face {
  font-family: Mulish-Italic;
  src: url(../public/font/Mulish-Italic.ttf);
}

@font-face {
  font-family: Mulish;
  src: url(../public/font/Mulish-Regular.ttf);
}

@font-face {
  font-family: Mulish-SemiBold;
  src: url(../public/font/Mulish-SemiBold.ttf);
}

@font-face {
  font-family: Mulish-Bold;
  src: url(../public/font/Mulish-Bold.ttf);
}

@font-face {
  font-family: Mulish-Black;
  src: url(../public/font/Mulish-Black.ttf);
}

/* Landscape styling */
@media only screen and (orientation: landscape) {
  .YoutubeVideo {
    width: auto;
    height: auto;
    align-self: center;
    padding-bottom: 390px;
    margin-bottom: 20px;
  }

  .YoutubeVideo iframe{
    width: 75%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }

  .headerModal {
    font-size: 22px;
  }
  
  .modalSubtitle {
    font-size: 14px;
  }

  .PhotoGalleryLandscape {
    width: 73%;
  }

  .SubSectionAfterYoutube {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin-top: 0px;
    margin-left: 24px;
    margin-right: 24px;
  }

  .PhotoAfterYoutube {
    width: 73vw;
  }

  .PinterestGrid {
    width: 73.2%;
  }
}

@media only screen and (orientation: portrait) {
  .headerModal {
    font-size: 4.7vw; /* 20pt */
  }

  .PinterestGrid {
    width: 89%;
  }
  
  .modalSubtitle {
    font-size: 3.1vw; /* 12pt */
  }

  .PhotoGallery {
    width: 90%;
  }

  .YoutubeVideo  {
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    border-radius: 10px;
  }
  
  .YoutubeVideo iframe {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .SubSectionAfterYoutube {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin-top: 8px;
    margin-left: 24px;
    margin-right: 24px;
  }

  .PhotoAfterYoutube {
    width: 91vw;
  }
}