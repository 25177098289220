.WrapperClosing {
    display: flex;
    flex-direction: column;
}

.ClosingStatement {
    display: flex;
    flex-direction: column;
    margin: 0 24px 40px 24px;
    opacity: 0; /* Initially hidden */
    transform: translateY(-50px); /* Start from slightly below */
    transition: opacity 1s ease, transform 1s ease; /* Smooth fade-in and slide-up transition */
}

.ClosingStatement.is-visible {
    opacity: 1; /* Visible when in view */
    transform: translateY(0); /* Slide up to original position */
}

.ClosingStatementHeader {
    display: flex;
    flex-direction: column;
}

.ClosingTitle {
    font-family: Mulish-Black;
    color: #C4AB90;
}

.ClosingJapanese {
    font-family: Mulish;
    color: #C4AB90;
    margin-bottom: 8px;
}

.ClosingStatement > p {
    margin-top: 24px;
    font-family: Mulish-Italic;
    color: #626061;
}

.Credits {
    background-color: #C4AB90;
    padding: 16px 24px 16px 24px;
    opacity: 0; /* Initially hidden */
    transition: opacity 1s ease; /* Smooth fade-in transition */
}

.Credits.is-visible {
    opacity: 1; /* Visible when in view */
}

.Credits > p {
    font-family: Mulish;
    color: white;
}


/* Landscape styling */
@media only screen and (orientation: landscape) {
    .ClosingTitle {
        font-size: 22px;
    }
    
    .ClosingJapanese {
        font-size: 16px;
    }
    
    .ClosingStatement > p {
        font-size: 16px;
    }

    .Credits > p {
        font-size: 14px;
    }
}

@media only screen and (orientation: portrait) {
    .ClosingTitle {
        font-size: 4.7vw; /* 20pt */
    }
    
    .ClosingJapanese {
        font-size: 3.5vw; /* 14pt */
    }
    
    .ClosingStatement > p {
        font-size: 3.7vw; /* 16pt */
    }

    .Credits > p {
        font-size: 3.1vw; /* 12pt */
    }
}