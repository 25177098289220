.WrapperRadio {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: flex;
}

.WrapperRadio > label {
  font-family: Mulish;
  color: #626061;
  margin-bottom: 8px;
  text-align: start;
}

.radio-buttons {
  display: flex;
  align-items: center;
  margin-left: 2px;
}

.radio-label {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-family: Mulish;
  color: #626061;
}

/* Hide the default radio button */
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  position: relative;
  background-color: transparent;
  border: 1px solid #E3E3E3;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  outline: none;
  margin-right: 6px;
  z-index: 1;
}

input[type="radio"]:checked{
  border: 1.5px solid #C4AB90;
}

/* Create a custom radio button */
input[type="radio"]::after {
  content: '';
  display: block;
  width: 7.5px;
  height: 7.5px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
}

/* Change the color of the checked radio button */
input[type="radio"]:checked::after {
  background-color: #C4AB90; /* Change to your desired color */
}

/* Landscape styling */
@media only screen and (orientation: landscape) {
  .WrapperRadio > label {
    font-size: 14px;
  }

  .radio-label {
    font-size: 12px;
  }
}

@media only screen and (orientation: portrait) {
  .WrapperRadio > label {
    font-size: 3.1vw; /* 12pt */
  }

  .radio-label {
    font-size: 3.1vw; /* 12pt */
  }
}