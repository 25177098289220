.text-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: flex;
}

.text-area > label {
    font-family: Mulish;
    color: #626061;
    margin-bottom: 8px;
    text-align: start;
}

.text-area > textarea {
    padding-top: 8px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 8px;
    border-radius: 8px;;
    display: inline-block;
    border: 1px solid #E3E3E3;
    font-family: Mulish;
}

textarea {
    outline: none; /* Remove default outline */
    border: 1px solid #E3E3E3; /* Default border color */
}

textarea:focus {
    border-color: #C4AB90; /* Change border color on focus */
}

textarea[type=text], select {
    color: #626061;
}

::placeholder {
    color: #ABABAB;
    background-color: transparent;
    border: none;
}

/* Landscape styling */
@media only screen and (orientation: landscape) {
    .text-area > label {
        font-size: 14px;
    }

    .text-area > textarea {
        font-size: 14px;
    }
}

@media only screen and (orientation: portrait) {
    .text-area > label {
        font-size: 3.1vw; /* 12pt */
    }

    .text-area > textarea {
        font-size: 3.1vw;
    }
}