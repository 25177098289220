.dropdown {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: flex;
}

.dropdown > label {
    font-family: Mulish;
    color: #626061;
    margin-bottom: 8px;
    text-align: start;
}

.dropdown-toggle {
    padding-top: 8px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 8px;
    display: flex;
    border: 1px solid #E3E3E3;
    font-family: Mulish;
    border-radius: 8px;
    text-align: start;
    color: #A8A8A8;
    justify-content: space-between;
}

.dropdown-toggle.filled {
    color: #626061;
}

.dropdown-toggle.disabled {
    background-color: #F6F6F6;
}

.dropdown-toggle.open {
    border-radius: 8px 8px 0px 0px;
}

.dropdown-content {
    position: relative;
}

.dropdown-menu {
    width: 100%;
    position: absolute;
    background-color: white;
    border: 1px solid #E3E3E3;
    border-radius: 0 0 8px 8px;
    border-top: none;
    box-sizing: border-box;
    z-index: var(dropdownZIndex);
}

ul {
    list-style-type: none;
}

.dropDownListItem {
    font-family: Mulish;
    color: #626061;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    border-bottom: 1px solid #E3E3E3;
    text-align: start;
    display: flex;
    justify-content: space-between;
}

li:last-child { border-bottom: none; }

/* Landscape styling */
@media only screen and (orientation: landscape) {
    .dropdown > label {
        font-size: 14px;
    }

    .dropdown-toggle {
        font-size: 14px;
    }

    .dropDownListItem {
        font-size: 14px;
    }
}

@media only screen and (orientation: portrait) {
    .dropdown > label {
        font-size: 3.1vw; /* 12pt */
    }

    .dropdown-toggle {
        font-size: 3.1vw;
    }

    .dropDownListItem {
        font-size: 3.1vw; /* 12pt */
    }
}