/* Container CSS */

.WrapperCover {
    display: flex;
    flex-direction: column;
}

.topCover {
    display: flex;
    flex-direction: row;
}

.coverLeft {
    display: flex;
    width: 15%;
    justify-content: flex-start;
    align-items: flex-start;
}

.coverMid {
    display: inline;
    color: transparent;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: flex;
    width: 70%;
    position: relative;
}

.coverRight {
    display: flex;
    width: 15%;
}

.dateRow {
    display: flex;
    justify-content: flex-end;
    margin-top: -40px;
}

.title {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    animation-name: textFadeIn;
    animation-duration: 2s;
}

.guestMessage{
    display: flex;
    flex-direction: column;
    animation-name: textFadeIn;
    animation-duration: 2s;
}

.dearText{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.coverDateWrapper {
    width: 10%;
    margin-right: 12px;
}

/* Component CSS */

p {
    font-family: Mulish-Italic;
    color: black;
    text-align: center;
    align-items: stretch;
    justify-content: stretch;
    height: flex;
    width: 100%;
    float: center;
}

.symbolLogo{
    margin-top: 24px;
    margin-left: 24px;
    width: 70%;
    height: auto;
    animation-name: logoFlewFadeIn;
    animation-duration: 2s;
    position: relative;
}

.mainCover {
    margin-top: 30%;
    width: 75%;
    height: auto;
    aspect-ratio: 1;
    animation-name: textFadeIn;
    animation-duration: 2s;
}

.coverDate {
    width: 70%;
    height: auto;
    animation-name: dateFlewFadeIn;
    animation-duration: 2s;
    position: relative;
}

.introText{
    font-family: Mulish;
    color: #626061;
}

.brideGroomNameText{
    margin-top: 2px;
    font-family: Mulish-Black;
    color: #C4AB90;
}

.dearText {
    font-family: Mulish;
    color: #626061;
}

.guestNameText {
    font-family: Mulish-Black;
    color: #C4AB90;
}

.invitationText > p{
    margin-top: 8px;
    font-family: Mulish;
    color: #626061;
}

.openInvitationButton {
    margin-bottom: 36px;
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.buttonWrapper {
    width: 100%;
}

.animationWrapper {
    width: 100%;
    height: auto;
    animation-name: buttonFlewFadeIn;
    animation-duration: 2s;
    position: relative;
    animation: bounce 0.5s cubic-bezier(0.8, 0, 1, 1) infinite alternate;
}

.openInvitationButton.hidden {
    opacity: 0;
}

.openInvitationButton.shown {
    opacity: 1;
}

/* styles.css */
@keyframes bounce {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(8px);
    }
  }

.coverFlower {
    display: flex;
    position: absolute;
    width: 15%;
    height: flex;
    aspect-ratio: 1;
    top: 37%;
    left: 76%;
    animation-name: fadeInFlower;
    animation-duration: 2s;
}

/* Animation */

@keyframes fadeInFlower {
    from {
        opacity: 0%;
    }
    to {
        opacity: 100%;
    }
}

@keyframes logoFlewFadeIn {
    from {
        top: -100%;
        opacity: 0%;
    }
    to {
        top: 0px;
        opacity: 100%;
    }
}

@keyframes dateFlewFadeIn {
    from {
        bottom: -100%;
        opacity: 0%;
    }
    to {
        bottom: 0px;
        opacity: 100%;
    }
}

@keyframes buttonFlewFadeIn {
    from {
        bottom: -100%;
        opacity: 0%;
    }
    to {
        bottom: 0px;
        opacity: 100%;
    }
}

@keyframes textFadeIn {
    from {
        opacity: 0%;
    }
    to {
        opacity: 100%;
    }
}

/* Landscape styling */
@media only screen and (orientation: landscape) {
    .topCover {
        width: 40%;
        align-self: center;
    }
    .dateRow {
        width: 40%;
        align-self: center;
        margin-top: -50px;
    }
    .introText {
        /* On landscape mode, the screen width is adjusted to 50%, 
        therefore the font should be done also for responsive design */
        font-size: 16px;
    }
    .brideGroomNameText {
        font-size: 32px;
    }
    .dearText {
        margin-top: 25px;
    }
    .dearText, .guestNameText, .invitationText > p {
        font-size: 14px;
    }
    .openInvitationButton {
        margin-top: 24px;
    }
}

@media only screen and (orientation: portrait) {
    .introText {
        font-size: 3.7vw; /* 16pt */
    }
    .brideGroomNameText {
        font-size: 7.5vw; /* 32pt */
    }
    .dearText {
        margin-top: 40px;
        font-size: 3.5vw; /* 14pt */
    }
    .guestNameText {
        font-size: 3.5vw; /* 14pt */
    }
    .invitationText > p {
        font-size: 3.5vw; /* 14pt */
    }
    .openInvitationButton {
        margin-top: 51px;
    }
}