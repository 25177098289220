.text-field {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: flex;
}

.text-field > label {
    font-family: Mulish;
    color: #626061;
    margin-bottom: 8px;
    text-align: start;
}

.text-field > input {
    padding-top: 8px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 8px;
    border-radius: 8px;;
    display: inline-block;
    border: 1px solid #E3E3E3;
    font-family: Mulish;
}

input[type=text], select {
    color: #626061;
}

input[type=text], ::placeholder{
    background-color: #F6F6F6;
    color: #A8A8A8;
    opacity: 1;
    border: 1px solid #E3E3E3;
}

/* Landscape styling */
@media only screen and (orientation: landscape) {
    .text-field > label {
        font-size: 14px;
    }

    .text-field > input {
        font-size: 14px;
    }
}

@media only screen and (orientation: portrait) {
    .text-field > label {
        font-size: 3.1vw; /* 12pt */
    }

    .text-field > input {
        font-size: 3.1vw;
    }
}