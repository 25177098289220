.audio-player {
    position: fixed;
    bottom: 20px;
    right: 16px;
    z-index: 1000; /* Ensure it stays on top of other elements */
}

.controls {
    width: 45px;
    height: 45px;
}