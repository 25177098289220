.WrapperBackground {
    display: flex;
    width: 100%;
    height: flex;
    flex-direction: column;
}

.logo {
    width: 100px;
    height: 132px;
    margin-bottom: 40px;
}

.UpperContent {
    display: flex;
    margin-top: 72px;
    height: flex;
    justify-content: center;
    align-items: bottom;
}

.japanText {
    margin-bottom: 16px;
}

.timeline {
    display: flex;
    flex-direction: column;
    opacity: 0; /* Initially hidden */
    transform: translateY(-50px); /* Start from slightly below */
    transition: opacity 1s ease, transform 1s ease; /* Smooth fade-in and slide-up transition */
}

.timeline.is-visible {
    opacity: 1; /* Visible when in view */
    transform: translateY(0); /* Slide up to original position */
}

.timeLineBorder {
    width: 1px;
    height: 100%;
    background-color: #000;
    margin: 0 auto;
    transform: rotate(180deg);
}

.titleDescription{
    display: flex;
    flex-direction: column;
    height: flex;
    justify-content: center;
}

.titleBackground > p{
    font-family: Mulish-Black;
    color: #C4AB90;
    text-align: start;
}

.descriptionBackground > p{
    margin-top: 16px;
    font-family: Mulish-Italic;
    color: #626061;
    text-align: start;
}

.signBackground > p {
    margin-top: 16px;
    font-family: Mulish-Italic;
    color: #626061;
    text-align: start;
    margin-bottom: 75px;
}

.BottomContent {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #C4AB90;
    align-items: center;
    justify-content: center;
    z-index: -1;
    opacity: 0; /* Initially hidden */
    transform: translateY(50px); /* Start from slightly below */
    transition: opacity 1s ease, transform 1s ease; /* Smooth fade-in and slide-up transition */
}

.BottomContent.is-visible {
    opacity: 1; /* Visible when in view */
    transform: translateY(0); /* Slide up to original position */
}

.BottomContentSentences > p {
    font-family: Mulish;
    color: white;
    text-align: center;
}

.BottomContentBible > p {
    margin-top: 8px;
    font-family: Mulish;
    color: white;
    text-align: center;
}

/* Landscape styling */
@media only screen and (orientation: landscape) {
    .timeline {
        display: none;
    }
    .logo {
        align-self: center;
    }
    .titleBackground > p {
        font-size: 22px;
        text-align: center;
    }
    .descriptionBackground > p {
        font-size: 12px;
        text-align: center; 
    }
    .signBackground > p {
        font-size: 12px;
        text-align: center;
    }
    .BottomContentSentences > p {
        margin-top: 4%;
        font-size: 16px;
    }
    .BottomContentBible > p {
        margin-bottom: 4%;
        font-size: 14px;
    }
}

@media only screen and (orientation: portrait) {
    .titleDescription {
        margin-left: 40px;
    }
    .titleBackground > p {
        font-size: 5.1vw; /* 20pt */
    }
    .descriptionBackground > p {
        font-size: 3.1vw; /* 12pt */
    }
    .signBackground > p {
        font-size: 3.1vw; /* 12pt */
    }
    .BottomContentSentences > p {
        margin-top: 8%;
        font-size: 3.5vw; /* 14pt */
    }
    .BottomContentBible > p {
        margin-bottom: 8%;
        font-size: 3.1vw; /* 12pt */
    }
}